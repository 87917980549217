export const bodyComponent = {
  mt: { xs: 10, sm: 12, md: 13, lg: 15 },
  mb: { xs: 5, sm: 6, md: 7, lg: 8 },
  marginLeft: { xs: 5, sm: 5, md: 7, lg: 9 },
  marginRight: { xs: 5, sm: 5, md: 7, lg: 9 },
  textAlign: "left",
};

export const bodyComponentText = {
  color: "#535353",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 12, sm: 14, md: 16, lg: 18 },
};
export const bodyComponentText1 = {
  color: "#535353",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 12, sm: 14, md: 16, lg: 18 },
  textAlign: "center",
  mb: { xs: 1.8, sm: 2.2, md: 2.6, lg: 3 },
};

export const bodyComponentHeader = {
  color: "#000000",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 25, sm: 32, md: 40, lg: 48 },
  textAlign: "center",
};

export const bodyComponentParam = {
  color: "#535353",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 15, sm: 16, md: 18, lg: 20 },
  mt: { xs: 0.8, sm: 1, md: 1.2, lg: 1.5 },
};

export const bodyComponentSubHeader = {
  color: "#000000",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 16, sm: 24, md: 32, lg: 40 },
  textAlign: "left",
  mt: { xs: 1.8, sm: 2.2, md: 2.6, lg: 3 },
};

export const bodyComponentLeftSubHeader = {
  color: "#000000",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 18, sm: 22, md: 27, lg: 30 },
  mt: { xs: 1, sm: 1.3, md: 1.6, lg: 1.8 },
  mb: { xs: 1, sm: 1.3, md: 1.6, lg: 1.8 },
};

export const bodyComponentSubLeftSubHeader = {
  color: "#000000",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 16, sm: 19, md: 22, lg: 25 },
  mt: { xs: 1, sm: 1.3, md: 1.6, lg: 1.8 },
  mb: { xs: 1, sm: 1.3, md: 1.6, lg: 1.8 },
};

export const bodyComponentStrong = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: { xs: 14, sm: 16, md: 18, lg: 20 },
  color: "#000000",
};

export const bodyComponentList = {
  mt: { xs: 0.8, sm: 0.8, md: 0.9, lg: 1 },
  mb: { xs: 0.8, sm: 0.8, md: 0.9, lg: 1 },
  fontSize: { xs: 14, sm: 16, md: 18, lg: 20 },
  fontFamily: "Poppins",
  fontStyle: "normal",
};

export const bodyComponentParamList = {
  color: "#535353",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 15, sm: 16, md: 18, lg: 20 },
  mt: { xs: 0.9, sm: 1.1, md: 1.3, lg: 1.5 },
  mb: { xs: 0.9, sm: 1.1, md: 1.3, lg: 1.5 },
};
