export const midHeaderText = {
  fontFamily: "AvenirNext-Medium",
  fontStyle: "normal",
  fontSize: { xs: 25, sm: 30, md: 35, lg: 40 },
  color: "#1d1d1d",
  paddingBotton: 1.8,
  fontWeight: "600",
  textTransform: "capitalize",
  textAlign: "center",
};

export const midHeaderContentText = {
  fontFamily: "AvenirNext-Medium",
  fontStyle: "normal",
  fontSize: { xs: 14, sm: 16, md: 18, lg: 20 },
  color: "#1d1d1d",
  paddingBotton: 1.8,
  fontWeight: "600",
  textTransform: "capitalize",
  textAlign: "center",
};
export const inputText = {
  width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
  marginTop: 2,
};

export const copyRight = {
  marginTop: 7.5,
  marginBottom: 7.5,
  fontFamily: "AvenirNext-Medium",
  fontStyle: "normal",
  fontSize: { md: 13, lg: 13 },
  color: "#000000",
  fontWeight: "400",
};

export const followStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: { md: 13, lg: 14 },
  color: "#000000",
  fontWeight: "400",
};

export const buttonAlignment = {
  background: "#FFFFFF",
  display: "flex",
  marginTop: 2.2,
};

export const subContentText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: { md: 13, lg: 16 },
  color: "#191919",
  fontWeight: "400",
};

export const subContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 7.5,
  width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
};

export const secondaryComponent = {
  height: 266,
  background: "#D5E330",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

export const secondaryComponentDownload = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: 2,
};

export const appStoreImage = {
  height: { xs: 30, sm: 33, md: 35, lg: 36 },
  width: { xs: 110, sm: 112, md: 115, lg: 120 },
  cursor: "pointer",
};
