import axios from "axios";

const api = axios.create({
  baseURL: "https://dashboard.pronto-pantry.com",
  // baseURL:"http://localhost:8085"
});

export const saveResidentFeedback = (body) => {
  return new Promise((resolve, reject) => {
    api
      .post("/pronotopantry/saveResidentFeedback", body)
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const saveManagerFeedback = (body) => {
  return new Promise((resolve, reject) => {
    api
      .post("/pronotopantry/saveManagerFeedback", body)
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const sendAccountDeleteReq = (body) => {
  return new Promise((resolve, reject) => {
    api
      .post("pronotopantry/deleteUserEmail", body)
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const onHandleSubscribe = (email) => {
  return new Promise((resolve, reject) => {
    console.log("apiDa", email);
    let value = Date.now();
    axios
      .post(
        `https://pronto-pantry.us13.list-manage.com/subscribe/post-json?u=d18a1ab4b1a07014477467ef9&id=236674d15a&f_id=00feeee7f0&c=jQuery19006096000455374317_1674038793978&EMAIL=${email}&tags=6684017&b_d18a1ab4b1a07014477467ef9_236674d15a=&subscribe=Subscribe&_=${value}`
      )
      .then((response) => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
