import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Pronto from "../../Assets/Images/support/pronto.svg";
import Amazon from "../../Assets/Images/support/amazon.svg";
import Basket from "../../Assets/Images/support/Basket.svg";
import ContactSupport from "../../Assets/Images/support/contact-support.svg";
import "./style.css";
import { Checkbox, Tab, Tabs, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Youtube from "../../Assets/Images/support/youtube.svg";
import Tiktok from "../../Assets/Images/support/tiktok.svg";
import FB from "../../Assets/Images/support/fb.svg";
import Insta from "../../Assets/Images/support/insta.svg";
import AppSupport from "../../Assets/Images/support/AppSupportIcon.svg";
import FranchiseSupport from "../../Assets/Images/support/FranchiseSupportIcon.svg";
import VendorSupport from "../../Assets/Images/support/VendorSupportIcon.svg";
import Arrow from "../../Assets/Images/support/GreenArrow.svg";
import { useLocation } from "react-router-dom";
import { sendAccountDeleteReq } from "../../API";
import { formatPhoneNumberToUS } from "../../Utils/Helpers/formatPhoneNumberToUS";
import HeaderBar from "../CommonComponets/HeaderBar";
import FooterBar from "../CommonComponets/FooterBar";

const imageArray = [Youtube, FB, Insta, Tiktok];

const ASSIT_CONTENT = [
  {
    iconSrc: AppSupport,
    header: "App Support",
    description:
      "With Direct to shelf inventory management, tech enable no cashier required Smart",
  },
  {
    iconSrc: VendorSupport,
    header: "Vendor Support",
    description:
      "With Direct to shelf inventory management, tech enable no cashier required Smart",
  },
  {
    iconSrc: FranchiseSupport,
    header: "Franchise Support",
    description:
      "With Direct to shelf inventory management, tech enable no cashier required Smart",
  },
];

const Support = ({ props }) => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [reason, setReason] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [enableButton, setEnableButton] = useState(false);

  const deleteRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      location?.pathname === "/support/deletereq" &&
      deleteRef &&
      deleteRef?.current
    ) {
      deleteRef?.current?.scrollIntoView();
    }
  }, [deleteRef, location]);

  useEffect(() => {
    validateEmail();
  }, [email]);

  useEffect(() => {
    if (
      email !== "" &&
      number !== "" &&
      name !== "" &&
      reason !== "" &&
      checkBox !== false &&
      emailError === "" &&
      number.length === 14
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [number, name, email, reason, checkBox]);

  const validateEmail = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email !== "") {
      const validEmail = emailRegex.test(email);
      setEmailError(!validEmail ? "* Please enter a valid email address" : "");
    } else {
      setEmailError("Please enter the email address");
    }
  };

  const formatPhoneNumber = (phone) => {
    const tempPhone = formatPhoneNumberToUS(phone);
    setNumber(tempPhone);
  };

  const handleOnSubmit = () => {
    if (enableButton) {
      const body = {
        name: name,
        email: email,
        phoneNumber: number,
        reason: reason,
      };
      sendAccountDeleteReq(body)
        .then((reqRes) => {
          setEnableButton(false);
          setSuccess(true);
          console.log("reqRes", reqRes);
        })
        .catch((reqErr) => console.log("reqErr", reqErr));
    }
  };

  const renderTabs = () => {
    return (
      <Tabs
        value={1}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#59be1b",
            height: 5,
          },
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tab
          disabled
          icon={null}
          label={
            <span
              style={{
                fontSize: 18,
                color: "black",
              }}
            >
              {""}
            </span>
          }
        />
        <Tab
          style={{
            root: {
              fontSize: 20,
            },
          }}
          icon={<img src={ContactSupport} />}
          label={
            <span
              style={{
                fontSize: 18,
                color: "black",
              }}
            >
              {"Contact Support"}
            </span>
          }
        />
        <Tab
          disabled
          style={{
            root: {
              fontSize: 20,
            },
          }}
          icon={null}
          label={
            <span
              style={{
                fontSize: 18,
                color: "black",
              }}
            >
              {""}
            </span>
          }
        />
      </Tabs>
    );
  };

  const renderAppBar = () => {
    return (
      <AppBar
        component="nav"
        sx={{
          pl: {
            md: 10,
            xs: 2,
          },
          pr: {
            md: 10,
            xs: 2,
          },
          boxShadow: "none",
          background: "#FFFFFF",
        }}
      >
        <Toolbar
          sx={{
            p: 0,
            pt: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              width: {
                md: 500,
                xs: "60%",
              },
            }}
          >
            <Box sx={{ width: "50%" }}>
              <img style={{ width: "100%" }} src={Pronto} />
            </Box>
            <Box
              sx={{
                width: "50%",
                mt: { md: 2, xs: 0.5 },
                ml: { md: 2, xs: 0.5 },
              }}
            >
              <img style={{ width: "100%" }} src={Amazon} />
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                md: 200,
                xs: "35%",
              },
            }}
          >
            <Button
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: `center`,
                width: "100%",
                height: 40,
                background: "#000",
                borderRadius: 1,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: {
                  md: 16,
                  xs: 8,
                },
                lineHeight: 24,
                textTransform: "uppercase",
                color: "#ffffff",
              }}
            >{`connect with us`}</Button>
          </Box>
        </Toolbar>
      </AppBar>
    );
  };

  const renderSupportBanner = () => {
    return (
      <Box
        sx={{
          pl: {
            xs: 5,
            md: 9,
          },
          background:
            "radial-gradient(ellipse at left, rgba(89, 190, 27, 0.06) 28.32%, transparent)",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            marginTop: 12,
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                p: {
                  xs: 5,
                  md: 0,
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="header-body-container">
                <Box
                  sx={{
                    fontSize: {
                      md: 50,
                      xs: 30,
                    },
                    mb: {
                      md: 3,
                      xs: 1,
                    },
                    mt: {
                      md: 3,
                      xs: 1,
                    },
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    color: "#1d1d1d",
                  }}
                  className="header-container-text"
                >
                  Customer Support
                </Box>
                {/* <div className="header-container-sub-text">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered
                </div> */}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              style={{
                padding: 0,
              }}
            >
              <img src={Basket} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderAssistContent = ({ header, iconSrc, description }) => {
    return (
      <Grid container spacing={2} xs={12} md={4} sx={{ pb: 5 }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            component={"img"}
            sx={{
              width: 50,
              height: 50,
              pt: 1,
              pr: 1,
            }}
            src={iconSrc}
          />
          <Box
            sx={{
              textAlign: "start",
              width: "85%",
            }}
          >
            <Box
              sx={{
                fontFamily: "Poppins",
                fontSize: 25,
                fontWeight: "600",
                pb: {
                  xs: 1,
                  md: 1.5,
                },
              }}
            >
              {header}
            </Box>
            <Box
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {description}{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                pt: 2,
                pb: 2,
              }}
            >
              <Button
                variant="text"
                sx={{
                  pr: 2,
                  color: "#59BE1B",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "500",
                }}
                endIcon={
                  <img style={{ height: 13, paddingLeft: 6 }} src={Arrow} />
                }
              >
                {"Contact Now"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderAssistContainer = () => {
    return (
      <Box
        sx={{
          pl: {
            xs: 5,
            md: 9,
          },
          pr: {
            xs: 5,
            md: 9,
          },
          display: "flex",
          flexDirection: "column",
          marginBottom: 1,
        }}
      >
        <Box
          sx={{
            fontSize: {
              md: 45,
              xs: 20,
            },
            mb: {
              md: 8,
              xs: 4,
            },
            mt: {
              md: 6,
              xs: 3,
            },
          }}
          className="mid-body-header-text1"
        >
          {"How Can We Assit You?"}
        </Box>
        <Box>
          <Grid
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                md: "row",
                xs: "column",
              },
            }}
          >
            {ASSIT_CONTENT.map((item) => renderAssistContent(item))}
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderAccDeleteForm = () => {
    return (
      <Box
        ref={deleteRef}
        sx={{
          pl: {
            xs: 5,
            md: 9,
          },
          pr: {
            xs: 5,
            md: 9,
          },
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          marginBottom: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            fontSize: {
              md: 50,
              xs: 25,
            },
            mb: {
              md: 3,
              xs: 1,
            },
            mt: {
              md: 3,
              xs: 1,
            },
          }}
          className="header-container-text"
        >
          Customer Support
        </Box>
        <Box
          sx={{
            fontSize: {
              md: 45,
              xs: 20,
            },
            mb: {
              md: 3,
              xs: 1,
            },
            mt: {
              md: 3,
              xs: 1,
            },
          }}
          className="mid-body-header-text1"
        >
          {"Delete My Account Request"}
        </Box>
        <Box
          className="sub-body-text"
          maxWidth={{ md: "45%", xs: "100%" }}
          sx={{ textAlign: "center" }}
        >
          {`To delete your account you can submit a request via the form below. 
            A support agent will contact you via email  to verify your request and make 
            sure there are no outstanding balance attached to your account`}
        </Box>
        {success ? (
          <Box
            sx={{
              p: { md: 4, xs: 2 },
              width: { md: "30%", xs: "100%" },
              mt: 3,
              mb: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#59be1b",
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 18,
              fontWeight: "500",
            }}
          >
            {
              "Your request has been sent successfully, a support agent will contact you shortly to proceed with your account deletion process"
            }
          </Box>
        ) : (
          <Box
            sx={{
              border: "1px rgba(0, 0, 0, 0.2) solid",
              p: { md: 4, xs: 2 },
              width: { md: "30%", xs: "100%" },
              mt: 3,
              mb: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "400",
                "& label.Mui-focused": {
                  color: "#59be1b",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#59be1b",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#59be1b",
                  },
                },
              }}
              fullWidth
              required
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              sx={{
                width: "100%",
                marginTop: 2,
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "400",
                "& label.Mui-focused": {
                  color: "#59be1b",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#59be1b",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#59be1b",
                  },
                },
              }}
              fullWidth
              required
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.replace(/\s/g, ""));
              }}
            />
            <TextField
              sx={{
                width: "100%",
                marginTop: 2,
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "400",
                "& label.Mui-focused": {
                  color: "#59be1b",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#59be1b",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#59be1b",
                  },
                },
              }}
              required
              fullWidth
              label="Phone Number"
              value={number}
              inputProps={{ maxLength: 14 }}
              onChange={(e) => {
                formatPhoneNumber(e.target.value);
              }}
            />
            <TextField
              minRows={6}
              required
              sx={{
                width: "100%",
                marginTop: 2,
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "400",
                "& label.Mui-focused": {
                  color: "#59be1b",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#59be1b",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#59be1b",
                  },
                },
              }}
              fullWidth
              label="Reason for your account deletion request"
              multiline
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
            <Box
              component={"span"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "400",
              }}
            >
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#59be1b",
                  },
                }}
                onClick={() => setCheckBox(!checkBox)}
              />
              {"I am sure I want to delete my account"}
            </Box>
            <Button
              variant="contained"
              autoCapitalize="false"
              disabled={!enableButton}
              sx={{
                backgroundColor: "#59BE1B",
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "400",
                color: "#FFF",
                display: "flex",
                alignSelf: "flex-start",
                mt: 4,
                mb: 1,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#59BE1B7A",
                },
              }}
              onClick={handleOnSubmit}
            >
              {"Submit"}
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <HeaderBar />
      {!(location?.pathname === "/support/deletereq") && renderSupportBanner()}
      {!(location?.pathname === "/support/deletereq") && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            background:
              "linear-gradient(0deg, rgba(89, 190, 27, 0.05) 28.32%, rgba(89, 190, 27, 0) 82.01%)",
          }}
        >
          {renderTabs()}
        </Box>
      )}
      {location?.pathname === "/support/deletereq"
        ? renderAccDeleteForm()
        : renderAssistContainer()}
      <FooterBar />
    </Box>
  );
};

export default Support;
