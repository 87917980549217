import "./App.css";
import Login from "./Components/Login";
import TermsOfService from "./Components/TermsOfService";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Downloads from "./Components/Downloads";
import Support from "./Components/Support";
import Privacy from "./Components/Privacy";
import Home from "./Components/Home";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/termsOfservice" element={<TermsOfService />} />
          <Route path="/download" element={<Downloads />} />
          <Route path="/support" element={<Support />}>
            <Route path="deletereq" element={<Support />} />
          </Route>
          
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
