/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./index.css";
import PDF from "../../Assets/DownloadPage.pdf";
import Logo from "../../Assets/Images/prontologo22.svg";
import QR from "../../Assets/Images/QR.jpg";
import APPSTORE from "../../Assets/Images/AppStore.svg";
import PLAYSTORE from "../../Assets/Images/PlayStore.svg";
import RECEIPT from "../../Assets/Images/Receipt.svg";
import ARROW from "../../Assets/Images/Arrow.svg";

const Downloads = (props) => {
  const renderQRContainer = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: 30,
        }}
      >
        <text class={"heading scan-and-go"}>{"SCAN AND GO"}</text>
        <text
          class={"heading hero-heading"}
          style={{
            fontFamily: "AvenirNext-Medium",
            fontSize: "25px",
            fontWeight: "500",
            color: "white",
            letterSpacing: 2,
          }}
        >
          {"Pronto is a self-service pantry, open24/7 for all residents"}
        </text>
        <img class={"qr-code"} src={QR} />
        <div className="stores-logos">
          <img
            class={"apple-store"}
            src={APPSTORE}
            style={{
              height: "100%",
              cursor: "pointer",
              // width:'50%'
            }}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/pronto-pantry/id1496061157"
              )
            }
          />
          <img
            class={"play-store"}
            src={PLAYSTORE}
            style={{
              height: "100%",
              cursor: "pointer",
              // width:'50%'
            }}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.pronto_pantry.app&hl=en_US&gl=US"
              )
            }
          />
        </div>
      </div>
    );
  };

  const renderContentContainer = () => {
    return (
      <div class={"steps-container"}>
        <div class={"steps-wrapper"}>
          <div class={"steps step-1"} style={{ marginBottom: "10%" }}>
            <text class="heading">{"1. Download the app"}</text>
            <div class="content" style={{ position: "relative" }}>
              {
                "Point your smartphone camera at this QR code\nand tap the link or visit www.pronto-pantry.com/download"
              }
              <img src={ARROW} class="arrowImageContainer" />
            </div>
          </div>
          <div class={"steps step-2"} style={{ marginBottom: "10%" }}>
            <text class="heading">{"2. Scan shelf tags to add items"}</text>
            <div class="content">
              {
                "Each product has a QR code located on the shelf price tag below the\nitem. Open the app and scan any product tag you would like to buy."
              }
            </div>
          </div>
          <div class={"steps step-3"} style={{ marginBottom: "10%" }}>
            <text class="heading">{"3. Checkout and go"}</text>
            <div class="content" style={{ position: "relative" }}>
              {
                "Checkout in the app. Your purchase receipt will\nbe emailed to you instantly."
              }
              <img src={RECEIPT} class="receiptImageContainer" />
            </div>
          </div>
        </div>
        <div class="mobileImageContainer"></div>
      </div>
    );
  };

  return (
    <div className="main_container">
      <div
        style={{ display: "flex", justifyContent: "flex-end", padding: "30px" }}
      >
        <img src={Logo} className="img" />
      </div>
      {renderQRContainer()}
      {renderContentContainer()}
      <div class="fotterContainer">
        <text class="content">
          {"Contact us anytime at support@pronto-pantry.com"}
        </text>
      </div>
    </div>
  );
};

export default Downloads;
