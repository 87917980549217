import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Pronto from "../../../Assets/Images/support/pronto.svg";
import Amazon from "../../../Assets/Images/support/amazon.png";
import {
  navButton,
  navBar,
  navBarTool,
  imageStyle,
  imageStyle1,
} from "./style";

const HeaderBar = () => {
  return (
    <Box>
      <AppBar component="nav" sx={navBar}>
        <Toolbar sx={navBarTool}>
          <Box
            variant="h6"
            component="div"
            sx={{ display: "flex", marginTop: 1 }}
          >
            <Box component="img" sx={imageStyle} src={Pronto} />
            <Box component="img" sx={imageStyle1} src={Amazon} />
          </Box>
          {/* <Box>
            <Button sx={navButton}>{`connect with us`}</Button>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HeaderBar;
