import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import AppStore from "../../../Assets/Images/AppStore.png";
import PlayStore from "../../../Assets/Images/PlayStore.png";
import Youtube from "../../../Assets/Images/support/youtube.svg";
import Tiktok from "../../../Assets/Images/support/tiktok.svg";
import FB from "../../../Assets/Images/support/fb.svg";
import Insta from "../../../Assets/Images/support/insta.svg";
import {
  midHeaderText,
  midHeaderContentText,
  inputText,
  copyRight,
  followStyle,
  buttonAlignment,
  subContentText,
  subContainer,
  secondaryComponent,
  secondaryComponentDownload,
  appStoreImage,
} from "./style";

const imageArray = [Youtube, FB, Insta, Tiktok];

const FooterBar = () => {
  return (
    <Box>
      <Box sx={secondaryComponent}>
        <Typography sx={midHeaderText}>Don't miss out any deal!</Typography>
        <Typography sx={midHeaderContentText}>
          Subscribe our Newsletter
        </Typography>

        <TextField
          sx={inputText}
          label="Enter Your Email Address"
          id="fullWidth"
        />
      </Box>
      <Box sx={secondaryComponentDownload}>
        <Typography sx={midHeaderText}>Download App</Typography>
        <Box sx={subContainer}>
          <Typography sx={subContentText}>
            To get started using PrOnto Pantry, simply download the App with the
            link below, fill out the information required and you can either
            order directly from the App and have it delivered to your home or
            visit the PrOnto Pantry in your building for a simple cashless scan
            and go experience.
          </Typography>
          <Box sx={buttonAlignment}>
            <Box
              component="img"
              sx={appStoreImage}
              src={AppStore}
              onClick={() => {}}
            />
            <Box sx={{ width: 20 }} />
            <Box
              component="img"
              sx={appStoreImage}
              src={PlayStore}
              onClick={() => {}}
            />
          </Box>
        </Box>
        <Typography sx={followStyle}>Follow Us</Typography>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {imageArray.map((ele, index) => (
            <Box
              key={index}
              sx={{
                padding: 0.8,
              }}
            >
              <img src={ele} />
            </Box>
          ))}
        </Box>
        <Typography sx={copyRight}>
          copyright (c) 2022 PrOnto. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterBar;
