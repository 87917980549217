export const navButton = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: `center`,
  width: { xs: 100, sm: 120, md: 150, lg: 209 },
  height: { xs: 28, sm: 40, md: 40, lg: 40 },
  background: "#1d1d1d",
  borderRadius: 1,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xs: 8, sm: 10, md: 12, lg: 16 },
  textTransform: "uppercase",
  color: "#ffffff",
};

export const navBar = {
  paddingLeft: 4,
  paddingRight: 4,
  background: "#FFFFFF",
  boxShadow: "none",
};

export const navBarTool = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const imageStyle = {
  // height: { xs: 40, sm: 45, md: 66, lg: 66 },
  width: { xs: 60, sm: 75, md: 220, lg: 220 },
};

export const imageStyle1 = {
  marginLeft: { xs: 0.8, md: 1.2, lg: 2.5 },
  height: { xs: 30, sm: 45, md: 66, lg: 66 },
  // width: { xs: 60, sm: 75, md: 220, lg: 220 },
};
