import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import Background from "../../Assets/Images/comingSoonBg.png";
import ProntoImage from "../../Assets/Images/prontoAmzLogo.png";
import { validateEmail } from "../../Utils";
import { alpha, styled } from "@mui/material/styles";
import { onHandleSubscribe } from "../../API";

const CustomTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "2px solid #000000",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: `${alpha(`#59BE1B`, 0.25)} 0 0 0 2px`,
      borderColor: `#59BE1B`,
    },
  },
}));

const Home = () => {
  const [focus, setFocus] = useState({ value: true, content: "" });
  const [email, setEmail] = useState("");
  const [result, setResult] = useState({ toggle: false, result: {} });

  const onValidate = (mail) => {
    let value = validateEmail(mail);
    return value;
  };

  const onClickSubmit = () => {
    let value = onValidate(email);
    if (value) {
      let apiResponse = onHandleSubscribe(email);
      setEmail("");
      setResult({ toggle: true, result: apiResponse });
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: {
            lg: "70%",
            xl: "70%",
          },
          alignItems: "center",
          pt: { xs: "35%", sm: "20%", md: "15%", lg: "13%" },
          pl: 2,
          pr: 2,
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "40%", sm: "40%", md: "25%", lg: "30%" },
            mb: { xs: 0.8, sm: 1.1, md: 1.2, lg: 1.5 },
          }}
          src={ProntoImage}
        />
        <Typography
          sx={{
            fontWeight: { xs: "300", sm: "400", md: "400", lg: "400" },
            marginBottom: 0.4,
            fontSize: {
              xs: 50,
              sm: 50,
              md: 72,
              lg: 72,
              xl: 72,
            },
            mb: 1,
          }}
        >
          Under Construction
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: 34,
              sm: 34,
              md: 45,
              lg: 48,
              xl: 48,
            },
            fontWeight: "400",
            mb: { xs: 5, sm: 5, md: 4, lg: 4 },
            textAlign: "center",
          }}
        >
          {`We are almost there! if you want to get notified when the website goes live, subscribe to our mailing list!`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "60%",
          }}
        >
          <CustomTextField
            required
            label="Email Address"
            id="reddit-input"
            variant="filled"
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            sx={{
              textAlign: "center",
              appearance: "none",
              borderRadius: 2,
            }}
            onFocus={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={(e) => {
              let value = onValidate(e.target.value);
              setEmail(e.target.value);
              setFocus({
                value,
                content: Boolean(e.target.value.length)
                  ? "Please enter a valid email address."
                  : `This field is required.`,
              });
            }}
          />
          {!focus.value && (
            <Box
              sx={{
                backgroundColor: `#6B0505`,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#fff",
                  p: "5px 10px",
                }}
              >
                {focus.content}
              </Typography>
            </Box>
          )}
          {result.toggle && (
            <Box
              sx={{
                backgroundColor: `#FFF`,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#59BE1B",
                  p: "5px 10px",
                }}
              >
                {`Thank you for subscribing!` || result?.result?.msg}
              </Typography>
            </Box>
          )}
          <Button
            variant="contained"
            onClick={onClickSubmit}
            sx={{
              width: {
                xs: 180,
                sm: 200,
                md: 200,
                lg: 220,
                xl: 220,
              },
              height: {
                xs: 35,
                sm: 38,
                md: 35,
                lg: 35,
                xl: 35,
              },
              mt: 3,
              backgroundColor: `#59BE1B`,
              color: "#fff",
              fontSize: {
                xs: 15,
                sm: 15,
                md: 14,
                lg: 15,
                xl: 16,
              },
              textTransform: "capitalize",
              borderRadius: 1,
              "&:hover": {
                backgroundColor: `#59BE1B`,
              },
            }}
          >
            subscribe
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: {
              xs: 14,
              sm: 16,
              md: 16,
              lg: 16,
            },
            fontWeight: "400",
            mt: 2,
            mb: {
              xs: 2,
              xl: 2,
            },
            textAlign: "center",
          }}
        >
          {`Contact us anytime at info@pronto-pantry.com`}
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
